import React, { useState } from 'react';
import '../../styles/css/main.css';
import '../../styles/css/sections.css';
import Header from '../homepage/sections/Header';
import ContactUsSection from '../homepage/sections/ContactUsSection';
import Footer from '../homepage/sections/Footer';
import {
  Button,
  Image,
  Stack,
  Container,
  Table,
  Card,
  Modal,
  Badge,
  Offcanvas,
} from 'react-bootstrap';

import eyeIcon from '../../assets/icons/eye-icon.svg';
import sortIcon from '../../assets/icons/sort-icon.svg';
import userIcon1 from '../../assets/images/avatars1.svg';
import userIcon2 from '../../assets/images/avatars2.svg';
import backArrow from '../../assets/icons/back_arrow.svg';
import rocketIcon from '../../assets/icons/rocket.svg';
import clockIcon from '../../assets/icons/clock-icon.svg';

const Tablex = () => {
  const [showMembersModal, setShowMembersModal] = useState(false);

  const handleMembersModalClose = () => setShowMembersModal(false);
  const handleMembersClick = () => {
    setShowMembersModal(true);
  };

  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);

  const handleProjectDetailsModalClose = () =>
    setShowProjectDetailsModal(false);
  const handleProjectDetailsClick = () => {
    setShowProjectDetailsModal(true);
  };

  return (
    <>
      <Header />
      <section className="primary hero-section">
        <Container>
          <Stack gap={2} direction="horizontal" className="mb-4">
            <Image src={backArrow} />{' '}
            <h5 className="m-0">No Poverty Projects</h5>
          </Stack>
          <Card>
            <Card.Body className="pt-1 pb-1">
              <Table responsive striped hover>
                <thead>
                  <tr className="align-middle">
                    <th>
                      <div className="d-flex justify-content-between align-items-center">
                        Project Name
                        <Button
                          variant="link"
                          className="icon-btn p-0"
                          title="sort"
                        >
                          <Image src={sortIcon} alt="sort" />
                        </Button>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex justify-content-between align-items-center">
                        Project Theme
                        <Button
                          variant="link"
                          className="icon-btn p-0"
                          title="sort"
                        >
                          <Image src={sortIcon} alt="sort" />
                        </Button>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex justify-content-between align-items-center">
                        Members
                        <Button
                          variant="link"
                          className="icon-btn p-0"
                          title="view members"
                        >
                          <Image src={sortIcon} alt="sort" />
                        </Button>
                      </div>
                    </th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="align-middle">
                    <td>Quality Education For Childrens</td>
                    <td>Quality Education - SDG 4</td>
                    <td>
                      <div
                        className="members-group-stack d-flex align-items-center"
                        onClick={handleMembersClick}
                      >
                        <Image
                          src={userIcon2}
                          alt="user"
                          className="table-user"
                        />
                        <Image
                          src={userIcon1}
                          alt="user"
                          className="table-user"
                        />
                        <Image
                          src={userIcon2}
                          alt="user"
                          className="table-user"
                        />
                        <span className="table-user members-count d-inline-block rounded-circle d-inline-flex align-items-center justify-content-center">
                          +2
                        </span>
                      </div>
                    </td>
                    <td className="text-center">
                      <Stack
                        direction="horizontal"
                        className="justify-content-center"
                      >
                        <Button
                          variant="link"
                          className="icon-btn p-0 m-0"
                          title="View"
                          onClick={handleProjectDetailsClick}
                        >
                          <Image src={eyeIcon} alt="view" />
                        </Button>
                      </Stack>
                    </td>
                  </tr>
                  <tr className="align-middle">
                    <td>Quality Education For Childrens</td>
                    <td>Quality Education - SDG 4</td>
                    <td>
                      <div
                        className="members-group-stack d-flex align-items-center"
                        onClick={handleMembersClick}
                      >
                        <Image
                          src={userIcon2}
                          alt="user"
                          className="table-user"
                        />
                        <Image
                          src={userIcon1}
                          alt="user"
                          className="table-user"
                        />
                        <Image
                          src={userIcon2}
                          alt="user"
                          className="table-user"
                        />
                        <span className="table-user members-count d-inline-block rounded-circle d-inline-flex align-items-center justify-content-center">
                          +2
                        </span>
                      </div>
                    </td>
                    <td className="text-center">
                      <Stack
                        direction="horizontal"
                        className="justify-content-center"
                      >
                        <Button
                          variant="link"
                          className="icon-btn p-0 m-0"
                          title="View"
                          onClick={handleProjectDetailsClick}
                        >
                          <Image src={eyeIcon} alt="view" />
                        </Button>
                      </Stack>
                    </td>
                  </tr>
                  <tr className="align-middle">
                    <td>Quality Education For Childrens</td>
                    <td>Quality Education - SDG 4</td>
                    <td>
                      <div
                        className="members-group-stack d-flex align-items-center"
                        onClick={handleMembersClick}
                      >
                        <Image
                          src={userIcon2}
                          alt="user"
                          className="table-user"
                        />
                        <Image
                          src={userIcon1}
                          alt="user"
                          className="table-user"
                        />
                        <Image
                          src={userIcon2}
                          alt="user"
                          className="table-user"
                        />
                        <span className="table-user members-count d-inline-block rounded-circle d-inline-flex align-items-center justify-content-center">
                          +2
                        </span>
                      </div>
                    </td>
                    <td className="text-center">
                      <Stack
                        direction="horizontal"
                        className="justify-content-center"
                      >
                        <Button
                          variant="link"
                          className="icon-btn p-0 m-0"
                          title="View"
                          onClick={handleProjectDetailsClick}
                        >
                          <Image src={eyeIcon} alt="view" />
                        </Button>
                      </Stack>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      </section>

      {/* Members Modal */}
      <Modal
        show={showMembersModal}
        onHide={handleMembersModalClose}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-dark m-0 fw-bold">Members in project</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="align-items-center">
          <h6 className="mb-3 text-dark">No Hunger</h6>
          <Stack direction="vertical" gap={3} className="mb-2">
            <Card className="rounded-3">
              <Card.Body className="p-2">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="justify-content-between pb-1"
                >
                  <Stack direction="horizontal" gap={2}>
                    <Image
                      src={userIcon1}
                      alt="user-icon"
                      className="global-user-thumb-large"
                    />
                    <Stack direction="vertical" gap={2}>
                      James Alexander
                      <Stack direction="horizontal" gap={2}>
                        {/* <Badge pill bg="light" text="dark" className="green">
                          Country Manager
                        </Badge> */}
                        <Badge pill bg="light" text="dark" className="red">
                          Hero
                        </Badge>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Card.Body>
            </Card>
            <Card className="rounded-3">
              <Card.Body className="p-2">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="justify-content-between pb-1"
                >
                  <Stack direction="horizontal" gap={2}>
                    <Image
                      src={userIcon1}
                      alt="user-icon"
                      className="global-user-thumb-large"
                    />
                    <Stack direction="vertical" gap={2}>
                      James Alexander
                      <Stack direction="horizontal" gap={2}>
                        <Badge pill bg="light" text="dark" className="green">
                          Country Manager
                        </Badge>
                        {/* <Badge pill bg="light" text="dark" className="red">
                          Hero
                        </Badge> */}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Card.Body>
            </Card>
            <Card className="rounded-3">
              <Card.Body className="p-2">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="justify-content-between pb-1"
                >
                  <Stack direction="horizontal" gap={2}>
                    <Image
                      src={userIcon1}
                      alt="user-icon"
                      className="global-user-thumb-large"
                    />
                    <Stack direction="vertical" gap={2}>
                      James Alexander
                      <Stack direction="horizontal" gap={2}>
                        {/* <Badge pill bg="light" text="dark" className="green">
                          Country Manager
                        </Badge> */}
                        <Badge pill bg="light" text="dark" className="red">
                          Hero
                        </Badge>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Card.Body>
            </Card>
            <Card className="rounded-3">
              <Card.Body className="p-2">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="justify-content-between pb-1"
                >
                  <Stack direction="horizontal" gap={2}>
                    <Image
                      src={userIcon1}
                      alt="user-icon"
                      className="global-user-thumb-large"
                    />
                    <Stack direction="vertical" gap={2}>
                      James Alexander
                      <Stack direction="horizontal" gap={2}>
                        {/* <Badge pill bg="light" text="dark" className="green">
                          Country Manager
                        </Badge> */}
                        <Badge pill bg="light" text="dark" className="red">
                          Hero
                        </Badge>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Card.Body>
            </Card>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button
            onClick={handleMembersModalClose}
            variant="outline-dark"
            className="d-flex align-items-center p-2 px-3"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas
        show={showProjectDetailsModal}
        onHide={handleProjectDetailsModalClose}
        placement="end"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title className="heading">
            <h3 className="text-dark m-0 fw-bold">Project Details</h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="bg-grey mb-3 rounded-3  border-0">
            <Card.Body>
              <Stack direction="horizontal" gap={3}>
                <Image
                  src={rocketIcon}
                  alt="rocket-icon"
                  className="global-user-thumb"
                />
                <h6 className="m-0">Quality Education For Childrens </h6>
              </Stack>
            </Card.Body>
          </Card>
          <Stack direction="horizontal" gap={5} className="mb-2">
            <span className="d-inline-flex align-items-center gap-2">
              <Image src={clockIcon} alt="clock-icon" />
              Created On
            </span>
            <span>10 July 2024</span>
          </Stack>
          <Stack direction="vertical" gap={3} className="mb-2 mt-3">
            <Card className="rounded-3">
              <Card.Body>
                <h6 className="fw-sb">Project Impact</h6>
                <p className="m-0">
                  This project aims to positively impact over 10,000 families by
                  providing clean water access and supporting sustainable
                  agricultural practices in drought-prone areas.
                </p>
              </Card.Body>
            </Card>

            <Card className="rounded-3">
              <Card.Body>
                <h6 className="fw-sb">Project Theme</h6>
                <p className="m-0">Quality Education SDG 4</p>
              </Card.Body>
            </Card>

            <Card className="rounded-3">
              <Card.Body>
                <h6 className="fw-sb">Description</h6>
                <p className="m-0">
                  The &quot;Green Future&quot; initiative seeks to transform
                  arid regions into thriving ecosystems by educating local
                  farmers, implementing solarpowered irrigation systems, and
                  building community resilience through sustainable practices.
                </p>
              </Card.Body>
            </Card>
          </Stack>
        </Offcanvas.Body>
        <Offcanvas.Header className="border-top justify-content-end">
          <Button
            variant="outline-dark"
            className="d-flex align-items-center p-2 px-3"
            onClick={handleProjectDetailsModalClose}
          >
            Close
          </Button>
        </Offcanvas.Header>
      </Offcanvas>
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Tablex;
