import React from 'react';
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="copy-text text-center d-flex justify-content-center align-items-center">
      <p className="m-0">
        © IMPACTIVATOR By Mpowa {currentYear}, All rights reserved
      </p>
    </footer>
  );
}

export default Footer;
