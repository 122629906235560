import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Image } from 'react-bootstrap';
import arrowIcon from '../../../assets/icons/arrow-icon.svg';
import { enquiryForm } from '../../../services/Homepage';
import { EnquiryFormData } from '../../../models/Homepage';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';

function FindOutMoreSection() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<EnquiryFormData>({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address: '',
    type: 'hero',
    sdgs: '0',
    industry: '0',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const isValid = Object.values(formData).every(
        (value) => value.trim() !== '' && /\S/.test(value)
      );

      if (isValid) {
        try {
          const resp = await enquiryForm(formData);
          toast.dismiss();
          toast.success(`${resp.message}`, {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          handleFormReset();
          // setValidated(false);
        } catch (error) {
          if (
            isAxiosError(error) &&
            error?.response?.data?.message[0]?.message
          ) {
            toast.dismiss();
            toast.error(`${error?.response?.data?.message[0]?.message}`, {
              position: 'top-right',
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
        }
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
      ...(e.target.type === 'radio' && { type: value }),
    }));

    // Custom validation
    if (id !== 'address') {
      const isValidInput = value.trim() !== '' && /\S/.test(value);
      e.target.setCustomValidity(
        isValidInput ? '' : 'Invalid input: Please enter a valid character.'
      );
    } else {
      // Allow whitespace in the address field
      e.target.setCustomValidity('');
    }
  };
  const handleFormReset = () => {
    setFormData({
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      address: '',
      type: 'hero',
      sdgs: '0',
      industry: '0',
    });
    setValidated(false);
  };
  return (
    <section className="secondary avatars-section" id="find-out-more">
      <Container>
        <Row className="justify-content-center mx-1 mx-md-3">
          <Col xs={12} className="text-center">
            <span className="hero-title-medium"> FIND OUT MORE</span>
            <h6 className="section-title mt-3 mb-3">
              Ready to learn more or get involved? Fill out the form below and
              our team will get in touch with you shortly.
            </h6>
          </Col>
        </Row>
        <Form
          className="row mx-1 mx-md-3"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                required
                type="text"
                placeholder="Enter First Name"
                minLength={1}
                maxLength={50}
                value={formData.first_name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid first name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="last_name">
              <Form.Label>Last Name</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                required
                type="text"
                placeholder="Enter Last Name"
                minLength={1}
                maxLength={50}
                value={formData.last_name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid last name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                required
                type="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="phone_number">
              <Form.Label>Phone</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                required
                type="tel"
                placeholder="Enter Phone Number"
                value={formData.phone_number}
                onChange={handleChange}
                pattern="^\+?\d{10,15}$"
                minLength={10}
                maxLength={15}
                // min={0}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid phone number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12}>
            <Form.Group className="mb-3" controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Address"
                value={formData.address}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide your address.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12}>
            <div className="mb-3 d-flex justify-content-center">
              <Form.Check
                inline
                label="Hero"
                type="radio"
                name="role"
                id="hero"
                value="hero"
                onChange={handleChange}
                checked={formData.type === 'hero'}
              />
              <Form.Check
                inline
                label="Impactor"
                type="radio"
                name="role"
                id="impactor"
                value="impactor"
                onChange={handleChange}
                checked={formData.type === 'impactor'}
              />
            </div>
          </Col>

          <Col sm={12}>
            <div className="mb-4 d-flex justify-content-center">
              <div className="w-50">
                <Form.Label className="form-label">
                  How Do You Want To Make An Impact?
                </Form.Label>
                <div className="d-flex justify-content-center gap-4">
                  <Form.Select
                    aria-label="Select SDGS"
                    id="sdgs"
                    value={formData.sdgs}
                    onChange={handleChange}
                    required
                  >
                    <option value="0">Select UN SDGS</option>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                  </Form.Select>

                  <Form.Select
                    aria-label="Select Industry"
                    id="industry"
                    value={formData.industry}
                    onChange={handleChange}
                    required
                  >
                    <option value="0">Select INDUSTRY </option>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </Col>

          <Col sm={12}>
            <div className="mb-3 d-flex justify-content-center gap-4">
              <Button
                size="lg"
                variant="outline-dark"
                type="reset"
                onClick={handleFormReset}
              >
                RESET
                <Image src={arrowIcon} alt="Hero Btn" className="mx-2" />
              </Button>
              <Button size="lg" type="submit">
                SUBMIT
                <Image src={arrowIcon} alt="Hero Btn" className="mx-2" />
              </Button>
            </div>
          </Col>
        </Form>
      </Container>
    </section>
  );
}

export default FindOutMoreSection;
