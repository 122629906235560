import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BlogData } from '../../../services/Homepage';
import { Categories } from '../../../models/Homepage';
function BlogSection() {
  const [data, setData] = useState<Categories[]>([]);
  const blog_data = async () => {
    try {
      const resp = await BlogData();
      setData(resp.data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    blog_data();
  }, []);
  return (
    <section className="primary blog-section" id="blog">
      <Container>
        <Row className="justify-content-center mx-1 mx-md-3">
          <Col xs={12} className="text-center">
            <span className="hero-title-medium stroke-heading light-stroke">
              BLOG
            </span>
            <h6 className="section-title mt-3 mb-3">
              Explore our blog for the latest insights, stories, and updates
              from the world of social entrepreneurship and sustainable
              development. Discover how Imp-activator and our heroes are driving
              positive change and innovation across diverse communities.
            </h6>
          </Col>
        </Row>
        <Row className="justify-content-start mx-1 mx-md-3 pe-0 pe-md-5">
          {data.length > 0 &&
            data.map((data, index) => (
              <Col key={index} sm={12} md={6} lg={4} className="mb-3">
                <Button className="btn-bordered-light btn-fill" variant="link">
                  {data.name}
                </Button>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
}

export default BlogSection;
