import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

// import mediaLogo1 from '../../../assets/icons/mediachannel1.svg';
// import mediaLogo2 from '../../../assets/icons/mediachannel2.svg';
// import mediaLogo3 from '../../../assets/icons/mediachannel3.svg';
// import mediaLogo4 from '../../../assets/icons/mediachannel4.svg';
// import mediaLogo5 from '../../../assets/icons/mediachannel5.svg';
import { featureData } from '../../../services/Homepage';

function FeaturedMediaSection() {
  const [logos, setLogos] = useState<string[]>([]);
  const feature_Data = async () => {
    try {
      const resp = await featureData();
      setLogos(resp.data.media);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    feature_Data();
  }, []);
  return (
    <section className="tertiary avatars-section">
      <Container>
        <Row className="justify-content-start mx-1 mx-md-3 mt-2">
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="hero-title-medium">
                FEATURED ON POPULAR MEDIA CHANNELS
              </span>
            </div>
            <h6 className="section-title mt-3 mb-3">
              Discover our impactful stories and initiatives featured on popular
              channels worldwide.
            </h6>
          </Col>
        </Row>
      </Container>
      <div className="hr-scroller">
        <div className="mover-1">
          {logos.length > 0 &&
            logos.map((logo, index) => (
              <Image key={index} src={logo} alt="media-logo" />
            ))}
          {logos.length > 0 &&
            logos.map((logo, index) => (
              <Image key={index} src={logo} alt="media-logo" />
            ))}
          {logos.length > 0 &&
            logos.map((logo, index) => (
              <Image key={index} src={logo} alt="media-logo" />
            ))}
          {logos.length > 0 &&
            logos.map((logo, index) => (
              <Image key={index} src={logo} alt="media-logo" />
            ))}
        </div>
      </div>
    </section>
  );
}

export default FeaturedMediaSection;
