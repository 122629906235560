import axiosInstance from '../axiosInstance'; // Import the configured axios instance
import { ENDPOINTS } from './endpoints';
import {
  AgendaResponse,
  AvatarResponse,
  BlogResponse,
  EnquiryFormData,
  EnquiryResponse,
  FeatureResponse,
  HeaderResponse,
  ImpactResponse,
  RoadMapResponse,
} from '../models/Homepage';

export const headerData = async (): Promise<HeaderResponse> => {
  const response = await axiosInstance.get<HeaderResponse>(
    `${ENDPOINTS.HEADER}`
  );
  return response.data;
};

export const agendaCardData = async (): Promise<AgendaResponse> => {
  const response = await axiosInstance.get<AgendaResponse>(
    `${ENDPOINTS.AGENDACARD}`
  );
  return response.data;
};

export const impactPointData = async (): Promise<ImpactResponse> => {
  const response = await axiosInstance.get<ImpactResponse>(
    `${ENDPOINTS.IMPACTPOINT}`
  );
  return response.data;
};

export const avatarData = async (): Promise<AvatarResponse> => {
  const response = await axiosInstance.get<AvatarResponse>(
    `${ENDPOINTS.AVATAR}`
  );
  return response.data;
};

export const BlogData = async (): Promise<BlogResponse> => {
  const response = await axiosInstance.get<BlogResponse>(`${ENDPOINTS.BLOG}`);
  return response.data;
};

export const featureData = async (): Promise<FeatureResponse> => {
  const response = await axiosInstance.get<FeatureResponse>(
    `${ENDPOINTS.FEATUREMEDIA}`
  );
  return response.data;
};

export const roadMapData = async (): Promise<RoadMapResponse> => {
  const response = await axiosInstance.get<RoadMapResponse>(
    `${ENDPOINTS.ROADMAP}`
  );
  return response.data;
};

export const enquiryForm = async (
  formData: EnquiryFormData
): Promise<EnquiryResponse> => {
  const response = await axiosInstance.post(
    `${ENDPOINTS.ENQUIRYFORM}`,
    formData
  );
  return response.data;
};
