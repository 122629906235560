import React from 'react';
import { Col, Image, Row, Button } from 'react-bootstrap';
import arrowIcon from '../../../assets/icons/arrow-icon.svg';
import heroBanner from '../../../assets/images/hero_avatars.png';
function HeroSection() {
  const handleScroll = () => {
    const element = document.getElementById('find-out-more');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <section className="primary hero-section">
      <Row className="mx-1 mx-md-5">
        <Col md={12} lg={6} className="text-center text-lg-start">
          <h3 className="hero-title">
            REVOLUTIONISING
            <div className="hero-title d-flex align-items-start justify-content-center justify-content-lg-start">
              <span className="d-ininle-block">SOCIAL</span>
              <span className="hero-title-large stroke-heading light-stroke ms-1">
                IMPACT
              </span>
            </div>
          </h3>
          <h3 className="hero-title mt-5 pt-4">
            <span>
              EMPOWER A
              <span className="stroke-heading light-stroke"> HERO</span>
            </span>
          </h3>
          <h3 className="hero-title-medium custom">
            <span>
              HOW DO YOU WANT TO
              <span className="stroke-heading light-stroke"> IMPACT?</span>
            </span>
          </h3>
          <div className="mt-3">
            <Button size="lg" onClick={handleScroll}>
              HERO
              <Image src={arrowIcon} alt="Hero Btn" className="mx-2" />
            </Button>
            <Button size="lg" className="mx-2" onClick={handleScroll}>
              IMPACTOR
              <Image src={arrowIcon} alt="Impactivator Btn" className="mx-2" />
            </Button>
          </div>
        </Col>
        <Col md={12} lg={6} className="text-center text-lg-start mt-5 mt-lg-0">
          <Image src={heroBanner} alt="hero" fluid />
        </Col>
      </Row>
    </section>
  );
}

export default HeroSection;
