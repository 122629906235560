import React from 'react';
import '../../styles/css/main.css';
import '../../styles/css/sections.css';
import { Col, Container, Row, Image } from 'react-bootstrap';
import Header from '../homepage/sections/Header';
import ContactUsSection from '../homepage/sections/ContactUsSection';
import Footer from '../homepage/sections/Footer';
import sdgicon1 from '../../assets/images/sdg_icons/sdg-image-1.png';
import sdgicon2 from '../../assets/images/sdg_icons/sdg-image-2.png';
import sdgicon3 from '../../assets/images/sdg_icons/sdg-image-3.png';
import sdgicon4 from '../../assets/images/sdg_icons/sdg-image-4.png';
import sdgicon5 from '../../assets/images/sdg_icons/sdg-image-5.png';
import sdgicon6 from '../../assets/images/sdg_icons/sdg-image-6.png';
import sdgicon7 from '../../assets/images/sdg_icons/sdg-image-7.png';
import sdgicon8 from '../../assets/images/sdg_icons/sdg-image-8.png';
import sdgicon9 from '../../assets/images/sdg_icons/sdg-image-9.png';
import sdgicon10 from '../../assets/images/sdg_icons/sdg-image-10.png';
import sdgicon11 from '../../assets/images/sdg_icons/sdg-image-11.png';
import sdgicon12 from '../../assets/images/sdg_icons/sdg-image-12.png';
import sdgicon13 from '../../assets/images/sdg_icons/sdg-image-13.png';
import sdgicon14 from '../../assets/images/sdg_icons/sdg-image-14.png';
import sdgicon15 from '../../assets/images/sdg_icons/sdg-image-15.png';
import sdgicon16 from '../../assets/images/sdg_icons/sdg-image-16.png';
import sdgicon17 from '../../assets/images/sdg_icons/sdg-image-17.png';
import heroBanner from '../../assets/images/hero_avatars.png';
import { useNavigate } from 'react-router-dom';

const Projects = () => {
  const sdgIcons = [
    sdgicon1,
    sdgicon2,
    sdgicon3,
    sdgicon4,
    sdgicon5,
    sdgicon6,
    sdgicon7,
    sdgicon8,
    sdgicon9,
    sdgicon10,
    sdgicon11,
    sdgicon12,
    sdgicon13,
    sdgicon14,
    sdgicon15,
    sdgicon16,
    sdgicon17,
  ];
  const navigate = useNavigate();
  const handleNaviagte = () => {
    navigate('/projectDetails');
  };
  return (
    <>
      <Header />
      <section className="primary hero-section">
        <Container>
          <Row className="mx-1 mx-md-5 ">
            <Col
              md={12}
              lg={7}
              className="text-center text-lg-start d-flex align-items-center"
            >
              <h3 className="hero-title">
                REVOLUTIONISING
                <div className="hero-title d-flex align-items-start justify-content-center justify-content-lg-start">
                  <span className="d-ininle-block">SOCIAL IMPACT</span>
                </div>
              </h3>
            </Col>
            <Col
              md={12}
              lg={5}
              className="text-center text-lg-start mt-5 mt-lg-0"
            >
              <Image src={heroBanner} alt="hero" fluid />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="secondary about-us-section">
        <Container>
          <Row className="d-flex justify-content-center mx-1 mx-md-3">
            <Col xs={12} className="text-center mb-3">
              <h6 className="section-title">
                <span className="text-muted">A </span>
                <span className="text-dark fw-bold">
                  DECENTRALISED ONLINE MARKETPLACE
                </span>
                <span className="text-muted"> CONNECTING </span>
                <span className="text-dark fw-bold">SOCIAL ENTREPRENEURS </span>
                <span className="text-muted">
                  IN DEVELOPING COUNTRIES WITH{' '}
                </span>
                <span className="text-dark fw-bold">INTERNATIONAL CLIENTS</span>
              </h6>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center m-1 m-md-3">
            {sdgIcons.map((icon, index) => (
              <Col xs={4} sm={3} md={2} key={index} onClick={handleNaviagte}>
                <Image src={icon} alt="sdg" className="img-fluid m-2" />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Projects;
