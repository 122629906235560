import React, { useEffect, useState } from 'react';
import '../../../styles/css/header.css';
import {
  Navbar,
  Container,
  Nav,
  Image,
  Button,
  Modal,
  // NavDropdown,
  // Dropdown,
} from 'react-bootstrap';

//Navbar
import profileImage from '../../../assets/images/profileImage.png';
import chatIcon from '../../../assets/icons/chat.svg';
import { headerData } from '../../../services/Homepage';
import { MenuItem } from '../../../models/Homepage';
import Loginform from '../../../utils/Loginform';
import SignUp from '../../../utils/SignUp';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../redux/authSlice';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/authSlice';
import { AppDispatch } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
// import Loginform from '../modelsdata/Loginform';
// import ForgotPassword from '../modelsdata/ForgotPassword';

function Header() {
  const [show, setShow] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [menuItem, setMenuItem] = useState<MenuItem[]>([]);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSignUpClose = () => setShowSignUp(false);
  const handleSignUpShow = () => setShowSignUp(true);

  const getHeaderData = async () => {
    try {
      const resp = await headerData();
      setMenuItem(resp.data.menu);
    } catch (error) {
      console.error('Error fetching header data:', error);
    }
  };
  // const handleNavigate = () => {
  //   // const adminUrl = BASE_URL;
  //   window.location.href = '/admin/';
  //   // navigate('/admin/');
  //   // href="/admin/"
  // };
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };
  useEffect(() => {
    getHeaderData();
  }, []);
  return (
    <>
      <header className="header-home">
        <Navbar expand="lg" data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="#home">IMPACTIVATOR</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-start justify-content-md-between nav-bg"
            >
              <Nav className="mx-4">
                {menuItem.map((item, index) => (
                  <Nav.Link key={index} href={item.url}>
                    {item.name}
                  </Nav.Link>
                ))}
              </Nav>
              <div className="d-flex align-items-center justify-content-start">
                <div className="profile position-relative mx-4">
                  <Image
                    src={chatIcon}
                    alt="Chat Icon"
                    className="position-absolute chat-icon"
                  />
                  <Image src={profileImage} alt="Profile Icon" />
                </div>
                {userId === 0 && (
                  <Button
                    variant="outline-light"
                    onClick={handleShow}
                    className="home-page-btns"
                  >
                    SIGN IN
                  </Button>
                )}
                {userId === 0 && (
                  <Button
                    variant="primary"
                    onClick={handleSignUpShow}
                    className="mx-2 home-page-btns"
                  >
                    SIGN UP
                  </Button>
                )}
                {userId !== 0 && (
                  <Button
                    variant="outline-light"
                    className="mx-2"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                )}
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <Modal show={show} onHide={handleClose} scrollable>
        <Loginform handleClose={handleClose} />
        {/* <ForgotPassword /> */}
      </Modal>

      <Modal show={showSignUp} onHide={handleSignUpClose} scrollable size="lg">
        <SignUp onClose={handleSignUpClose} />
      </Modal>
    </>
  );
}

export default Header;
