import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  role: string;
  id: number | 0;
  token: string | null;
}

// Retrieve stored token, role, and id from localStorage
const token = localStorage.getItem('token');
const role = localStorage.getItem('role');
const id = localStorage.getItem('user_id');

const initialState: AuthState = {
  isAuthenticated: !!token,
  role: role || 'guest',
  id: id ? Number(id) : 0,
  token: token,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{ token: string; role: string; id: number }>
    ) => {
      state.isAuthenticated = true;
      state.role = action.payload.role;
      state.id = action.payload.id;
      state.token = action.payload.token;

      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('role', action.payload.role);
      localStorage.setItem('user_id', action.payload.id.toString());
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.role = 'guest';
      state.id = 0;
      state.token = null;

      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('user_id');
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;

export const selectUserId = (state: { auth: AuthState }) => state.auth.id;
