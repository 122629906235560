import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { roadMapData } from '../../../services/Homepage';
import { Cards } from '../../../models/Homepage';
function RoadMapSection() {
  const [data, setData] = useState<Cards[]>([]);

  const road_Map_Data = async () => {
    try {
      const resp = await roadMapData();
      setData(resp.data.cards);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    road_Map_Data();
  }, []);
  return (
    <section className="primary roadmap-section">
      <Container>
        <Row className="justify-content-center mx-1 mx-md-3">
          <Col className="text-center">
            <span className="hero-title-medium stroke-heading light-stroke">
              ROADMAP
            </span>
            <h6 className="section-title mt-3 mb-3">
              Explore Impactivator&#39;s journey towards greater impact and
              innovation. Stay tuned as we achieve milestones, expand our reach,
              and empower more communities worldwide.
            </h6>
          </Col>
        </Row>
        <Row className="flex-column align-items-center ms-2">
          {data.length > 0 &&
            data.map((data, index) => (
              <Col key={index} lg={6} md={9} sm={9} xs={9} className="pb-4">
                <Card border="light" className="bg-transparent">
                  <Card.Body className="d-flex align-items-center gap-4 flex-column flex-md-row">
                    <div className="d-flex flex-column text-center text-white">
                      <Card.Img
                        src={data.image}
                        alt="Card image"
                        className="sdg-focus mb-2 ms-2"
                      />
                      {data.name}
                    </div>
                    <div>
                      <Card.Title className="text-white mb-3">
                        {data.title}
                      </Card.Title>
                      <Card.Text className="text-white">
                        {data.description}
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
}

export default RoadMapSection;
