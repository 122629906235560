// const BASE_URL = `http://${window.location.hostname}:5000`;
const BASE_URL = process.env.REACT_APP_MY_URL;
export const ENDPOINTS = {
  HEADER: `${BASE_URL}/home/header`,
  AGENDACARD: `${BASE_URL}/home/agenda-cards`,
  IMPACTPOINT: `${BASE_URL}/home/impact-points`,
  AVATAR: `${BASE_URL}/home/heroes`,
  BLOG: `${BASE_URL}/home/blogs`,
  FEATUREMEDIA: `${BASE_URL}/home/featured-on-media`,
  ROADMAP: `${BASE_URL}/home/roadmap`,
  ENQUIRYFORM: `${BASE_URL}/home/enquiry`,
  SIGNUP: `${BASE_URL}/users/hero/signup`,
  COUNTRYLISTS: `${BASE_URL}/users/country`,
  LOGIN: `${BASE_URL}/admin/login`,
};
