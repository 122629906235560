import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row, Modal, Form } from 'react-bootstrap';
import arrowIcon from '../assets/icons/arrow-icon.svg';
import { CountryLists, SingUp } from '../services/Auth';
import { Country } from '../models/Auth';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
interface SignUpProps {
  onClose: () => void;
}
const SignUp: React.FC<SignUpProps> = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    country: 0,
    companyName: '',
    gender: '',
    address: '',
    type: 'hero',
  });
  const [validated, setValidated] = useState(false);
  const [countryList, setCountryList] = useState<Country[]>([]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, type, value, checked, required } =
      e.target as HTMLInputElement;

    // Handle phone number separately
    let validatedValue = value;
    if (name === 'phone') {
      // Remove non-digit characters
      validatedValue = value.replace(/\D/g, '');
      e.target.value = validatedValue; // Update input value with only digits
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : validatedValue,
      ...(type === 'radio' && { type: value }),
    }));

    // Custom validation only for required fields
    if (required) {
      if (name === 'phone') {
        if (validatedValue.length < 10 || validatedValue.length > 15) {
          e.target.setCustomValidity(
            'Phone number must be between 10 and 15 digits.'
          );
        } else {
          e.target.setCustomValidity('');
        }
      } else {
        // General validation for other required fields
        if (value.trim() === '' || !/\S/.test(value)) {
          e.target.setCustomValidity(
            'Invalid input: Please enter a valid character.'
          );
        } else {
          e.target.setCustomValidity('');
        }
      }
    } else {
      // Clear any previous custom validity message for non-required fields
      e.target.setCustomValidity('');
    }
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        setValidated(true);
      } else {
        const data = {
          country_id: formData.country,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          roles: formData.type === 'hero' ? ['3'] : ['5'],
          gender: formData.gender,
          phone: formData.phone,
          company: formData.companyName,
          address: formData.address,
        };
        await SingUp(data);
        toast.dismiss();
        toast.success(
          `Your account registered successfully, Please check email for account activation.`,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        );
        onClose();
      }
    } catch (error) {
      if (isAxiosError(error) && error?.response?.data?.message[0]?.message) {
        toast.dismiss();
        toast.error(`${error?.response?.data?.message[0]?.message}`, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
      if (isAxiosError(error) && error?.response?.data?.message) {
        toast.dismiss();
        toast.error(`${error?.response?.data?.message}`, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  };
  const getCountryList = async () => {
    try {
      const resp = await CountryLists();
      setCountryList(resp.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountryList();
  }, []);
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="text-dark m-0 fw-bold">Sign Up</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col sm={12}>
              <div className="mb-3 d-flex">
                <Form.Check
                  className="text-dark"
                  inline
                  label="Hero"
                  type="radio"
                  name="role"
                  id="hero"
                  value="hero"
                  checked={formData.type === 'hero'}
                  onChange={handleChange}
                />
                <Form.Check
                  className="text-dark"
                  inline
                  label="Impactor"
                  type="radio"
                  name="role"
                  id="impactor"
                  value="impactor"
                  checked={formData.type === 'impactor'}
                  onChange={handleChange}
                />
              </div>
            </Col>

            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">First Name</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  maxLength={50}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid first name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">Last Name</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  maxLength={50}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid last name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">Phone</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  pattern="^\+?\d{10,15}$"
                  minLength={10}
                  maxLength={15}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid phone number.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">Email</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  maxLength={100}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">Country</Form.Label>
                <span className="text-danger">*</span>
                <Form.Select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a country</option>
                  {countryList.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.nicename}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a country.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  maxLength={200}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid company name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">Gender</Form.Label>
                <Form.Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Others</option>
                  <option value="prefer">Prefer Not to say</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select your gender.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label className="text-dark">Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  maxLength={200}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide your address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Button type="submit" variant="primary" size="lg" className="w-100">
            SIGN UP
            <Image src={arrowIcon} alt="Arrow Icon" className="mx-2" />
          </Button>
        </Form>
      </Modal.Body>
    </>
  );
};

export default SignUp;
