import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
function ContactUsSection() {
  return (
    <section className="primary contact-section" id="contact">
      <Container>
        <Row className="justify-content-between mx-1 mx-md-3">
          <Col xs={12}>
            <span className="hero-title-medium stroke-heading light-stroke">
              CONTACT
            </span>
          </Col>
          <Col sm={12} md={12} lg={5}>
            <h6 className="section-title mt-5 mb-1">IMPACTIVATOR</h6>
            <p className="pe-2">
              Mpowa employs AI in various aspects of our platform, enabling us
              to automate, scale and digitally twin real world assets and people
              with users of the platform.
            </p>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <h6 className="section-title mt-5 mb-1">More</h6>
            <ul className="list-group footer-links links">
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Term of service</a>
              </li>
              <li>
                <a href="#">Bitcoin Legal</a>
              </li>
              <li>
                <a href="#">MPOWA</a>
              </li>
              <li>
                <a href="#">MPOWAImpact</a>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={6} lg={4} className="pe-0">
            <h6 className="section-title mt-5 mb-1">Mail Addresses</h6>
            <ul className="list-group footer-links mail-links">
              <li>
                <a href="mailto:uk.info@mpowa.io">uk.info@mpowa.io (UK)</a>
              </li>
              <li>
                <a href="mailto:kenya.info@mpowa.io">
                  kenya.info@mpowa.io (Kenya)
                </a>
              </li>
              <li>
                <a href="mailto:malawi.info@mpowa.io">
                  malawi.info@mpowa.io (Malawi)
                </a>
              </li>
              <li>
                <a href="mailto:congo.info@mpowa.io">congo.info@mpowa.io</a>
              </li>
              <li>
                <a href="mailto:southafrica.info@mpowa.io">
                  southafrica.info@mpowa.io (South Africa)
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactUsSection;
