import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import arrowIcon from '../../../assets/icons/arrow-icon.svg';

import CardSlider from '../Cardslider';
import { avatarData } from '../../../services/Homepage';

function AvatarSection() {
  const [cards, setCards] = useState<string[]>([]);
  const avatar_Data = async () => {
    try {
      const resp = await avatarData();
      setCards(resp.data.heroes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    avatar_Data();
  }, []);

  const totalCards = cards.length;
  return (
    <section className="tertiary avatars-section" id="marketplace">
      <Container>
        <Row className="justify-content-start mx-1 mx-md-3">
          <Col sm={12}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="hero-title-medium">
                OUR HEROeS & THEIR UNIQUE NFTS
              </span>
              <Button
                size="lg"
                variant="outline-dark"
                className="d-flex align-items-center"
              >
                More
                <Image src={arrowIcon} alt="Hero Btn" className="mx-2" />
              </Button>
            </div>
            <h6 className="section-title mt-3 mb-3">
              Meet our heroes dedicated to tackling UN SDG-aligned challenges
              with resilience and innovation, driving positive change in their
              communities.
            </h6>
          </Col>
          <Col sm={12}>
            <div className="position-relative">
              <CardSlider cards={cards} totalCards={totalCards} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AvatarSection;
