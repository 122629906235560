import axios from 'axios';
import store from './redux/store';
import { logout } from './redux/authSlice';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_MY_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor for adding the token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    // Determine if the request requires authentication
    const isAuthRequired =
      !config.url?.includes('/public/') && !config.headers?.isOpenApi;

    if (token && isAuthRequired) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.message && error?.code === 'ERR_NETWORK') {
      toast.dismiss();
      toast.error(`${error?.message}`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    if (error.response && error.response.status === 401) {
      // Unauthorized access - possibly due to an expired token
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
