// CardSlider.tsx
import React, { useState } from 'react';
import { Card, Button, Row, Image } from 'react-bootstrap';

const CardSlider: React.FC<{ cards: string[]; totalCards: number }> = ({
  cards,
  totalCards,
}) => {
  const [start, setStart] = useState(0); // Start index of visible cards
  const [touchStartX, setTouchStartX] = useState(0);

  // Determine number of cards per slide based on viewport width
  const cardsPerSlide = useCardsPerSlide();

  // Function to handle scroll to the left
  const scrollLeft = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };

  // Function to handle scroll to the right
  const scrollRight = () => {
    if (start < totalCards - cardsPerSlide) {
      setStart(start + 1);
    }
  };

  // Handle touch start
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  // Handle touch move
  const handleTouchMove = (e: React.TouchEvent) => {
    const touchMoveX = e.touches[0].clientX;
    const touchDeltaX = touchMoveX - touchStartX;

    // Determine swipe direction and scroll cards accordingly
    if (touchDeltaX > 50) {
      // Swipe right
      scrollLeft();
    } else if (touchDeltaX < -50) {
      // Swipe left
      scrollRight();
    }
  };

  return (
    <>
      <Row className="">
        <div
          className="d-flex justify-content-center card-slider m-auto"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {cards.slice(start, start + cardsPerSlide).map((card, index) => (
            <Card key={index} className="avatar-card d-inline-block">
              <Image src={card} alt="" fluid />
            </Card>
          ))}
        </div>
      </Row>
      <div className="navigation-buttons" style={{ textAlign: 'center' }}>
        <Button
          className="slider-button left"
          onClick={scrollLeft}
          disabled={start === 0}
        >
          &lt;
        </Button>
        <Button
          className="slider-button right"
          onClick={scrollRight}
          disabled={start >= totalCards - cardsPerSlide}
        >
          &gt;
        </Button>
      </div>
    </>
  );
};

// Hook to calculate number of cards per slide based on viewport width
const useCardsPerSlide = () => {
  const [cardsPerSlide, setCardsPerSlide] = useState(3); // Default number of cards per slide

  // Adjust number of cards per slide based on viewport width
  const handleResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 576) {
      setCardsPerSlide(2); // 1 card per slide on extra small screens
    } else if (windowWidth < 768) {
      setCardsPerSlide(2); // 2 cards per slide on small screens
    } else if (windowWidth < 992) {
      setCardsPerSlide(3); // 3 cards per slide on medium screens
    } else if (windowWidth < 1200) {
      setCardsPerSlide(4); // 3 cards per slide on medium screens
    } else {
      setCardsPerSlide(5); // 4 cards per slide on larger screens
    }
  };

  // Listen for window resize events
  React.useEffect(() => {
    handleResize(); // Initial call to set the correct number of cards
    window.addEventListener('resize', handleResize); // Listen for resize events
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up listener on component unmount
    };
  }, []);

  return cardsPerSlide;
};

export default CardSlider;
