import axiosInstance from '../axiosInstance'; // Import the configured axios instance
import { ENDPOINTS } from './endpoints';
import { AuthResponse, CountryListResponse, FormData } from '../models/Auth';

export const SingUp = async (formData: FormData): Promise<AuthResponse> => {
  return axiosInstance
    .post(`${ENDPOINTS.SIGNUP}`, formData)
    .then((res) => res.data);
};

export const CountryLists = async (): Promise<CountryListResponse> => {
  return axiosInstance
    .get<CountryListResponse>(`${ENDPOINTS.COUNTRYLISTS}`)
    .then((res) => res.data);
};

export const adminLogin = async (
  email: string,
  password: string
): Promise<AuthResponse> => {
  return axiosInstance
    .post(`${ENDPOINTS.LOGIN}`, { email, password })
    .then((res) => res.data);
};
