import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

import brandLogo1 from '../../../assets/icons/logos-1.svg';
import brandLogo2 from '../../../assets/icons/logos-2.svg';
import brandLogo3 from '../../../assets/icons/logos-3.svg';
import brandLogo4 from '../../../assets/icons/logos-4.svg';
import brandLogo5 from '../../../assets/icons/logos-5.svg';
import arrowIcon from '../../../assets/icons/arrow-icon.svg';

import { agendaCardData } from '../../../services/Homepage';
import { useNavigate } from 'react-router-dom';

function AgendaSection() {
  const [agenda, setAgenda] = useState<string[]>([]);

  const navigate = useNavigate();
  const getAgendaData = async () => {
    try {
      const resp = await agendaCardData();
      setAgenda(resp.data.cards);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectMoreClick = () => {
    navigate('/projects');
  };
  useEffect(() => {
    getAgendaData();
  }, []);
  return (
    <section className="secondary about-us-section">
      <Container>
        <Row className="d-flex justify-content-center mx-1 mx-md-3">
          <Col sm={12}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="section-title">
                <span className="text-muted">A </span>
                <span className="text-dark fw-bold">
                  DECENTRALISED ONLINE MARKETPLACE
                </span>
                <span className="text-muted"> CONNECTING </span>
                <span className="text-dark fw-bold">SOCIAL ENTREPRENEURS </span>
                <span className="text-muted">
                  IN DEVELOPING COUNTRIES WITH{' '}
                </span>
                <span className="text-dark fw-bold">INTERNATIONAL CLIENTS</span>
              </span>
              <Button
                onClick={handleProjectMoreClick}
                size="lg"
                variant="outline-dark"
                className="d-flex align-items-center"
              >
                More
                <Image src={arrowIcon} alt="Hero Btn" className="mx-2" />
              </Button>
            </div>
          </Col>
        </Row>
        {/* <Row className="d-flex justify-content-center mx-1 mx-md-3">
          <Col xs={12} className="text-center mb-3">
            <h6 className="section-title">
              <span className="text-muted">A </span>
              <span className="text-dark fw-bold">
                DECENTRALISED ONLINE MARKETPLACE
              </span>
              <span className="text-muted"> CONNECTING </span>
              <span className="text-dark fw-bold">SOCIAL ENTREPRENEURS </span>
              <span className="text-muted">IN DEVELOPING COUNTRIES WITH </span>
              <span className="text-dark fw-bold">INTERNATIONAL CLIENTS</span>
            </h6>
          </Col>
        </Row> */}

        <Row className="d-flex justify-content-center m-1 m-md-3">
          {agenda.length > 0 &&
            agenda.map((item, index) => (
              <Col key={index} xs={4} sm={3} md={2}>
                <Image
                  src={item}
                  alt={`SDG${index + 1}`}
                  className="img-fluid m-2"
                />
              </Col>
            ))}
        </Row>
      </Container>
      <div className="hr-scroller">
        <div className="mover-1">
          <Image src={brandLogo1} alt="brand-logo" />
          <Image src={brandLogo2} alt="brand-logo" />
          <Image src={brandLogo3} alt="brand-logo" />
          <Image src={brandLogo4} alt="brand-logo" />
          <Image src={brandLogo5} alt="brand-logo" />

          <Image src={brandLogo1} alt="brand-logo" />
          <Image src={brandLogo2} alt="brand-logo" />
          <Image src={brandLogo3} alt="brand-logo" />
          <Image src={brandLogo4} alt="brand-logo" />
          <Image src={brandLogo5} alt="brand-logo" />

          <Image src={brandLogo1} alt="brand-logo" />
          <Image src={brandLogo2} alt="brand-logo" />
          <Image src={brandLogo3} alt="brand-logo" />
          <Image src={brandLogo4} alt="brand-logo" />
          <Image src={brandLogo5} alt="brand-logo" />
        </div>
      </div>
    </section>
  );
}

export default AgendaSection;
