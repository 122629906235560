import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import { impactPointData } from '../../../services/Homepage';
import { Points } from '../../../models/Homepage';

function ImpactSection() {
  const [impactItems, setImpactItems] = useState<Points[]>([]);
  const impactData = async () => {
    try {
      const resp = await impactPointData();
      setImpactItems(resp.data.points);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    impactData();
  }, []);
  return (
    <section className="primary impact-section">
      <Container>
        <Row className="justify-content-center mx-1 mx-md-3">
          <Col xs={12} className="text-center">
            <span className="hero-title-medium stroke-heading light-stroke">
              {' '}
              impact
            </span>
            <h6 className="section-title mt-3 mb-3">
              Impactivator has driven significant impact across diverse
              communities, empowering individuals worldwide through sustainable
              initiatives.
            </h6>
          </Col>

          {impactItems.map((item, index) => (
            <Col key={index} xs={12} md={6} lg={6}>
              <ListGroup>
                <ListGroup.Item className="d-flex align-items-center gap-3">
                  <Image src={item.icon} alt="icon" />
                  <p className="text-white m-0">{item.title}</p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default ImpactSection;
