import React from 'react';
import '../../../styles/css/main.css';
import '../../../styles/css/sections.css';
import Header from '../sections/Header';
import HeroSection from '../sections/HeroSection';
import AgendaSection from '../sections/AgendaSection';
import ImpactSection from '../sections/ImpactSection';
import AvatarSection from '../sections/AvatarSection';
import FindOutMoreSection from '../sections/FindOutMoreSection';
import BlogSection from '../sections/BlogSection';
import FeaturedMediaSection from '../sections/FeaturedMediaSection';
import ChannelSection from '../sections/ChannelSection';
import RoadMapSection from '../sections/RoadMapSection';
import ContactUsSection from '../sections/ContactUsSection';
import Footer from '../sections/Footer';

const Layout = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <AgendaSection />
      <ImpactSection />
      <AvatarSection />
      <FindOutMoreSection />
      <BlogSection />
      <FeaturedMediaSection />
      <ChannelSection />
      <RoadMapSection />
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Layout;
