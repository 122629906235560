import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import mediaThumb from '../../../assets/images/media-thumb.png';
import mediaThumb1 from '../../../assets/images/media-thumb-1.png';
import mediaThumb2 from '../../../assets/images/media-thumb-2.png';
import mediaThumb3 from '../../../assets/images/media-thumb-3.png';

function ChannelSection() {
  return (
    <section className="secondary channel-section" id="channel">
      <Container>
        <Row className="justify-content-center mx-1 mx-md-3">
          <Col xs={12} className="text-center">
            <span className="hero-title-medium">
              {' '}
              WATCH VIDEOS ON{' '}
              <span className="stroke-heading dark-storke">OUR CHANNEL</span>
            </span>
            <h6 className="section-title mt-3 mb-3">
              Discover our impactful stories and initiatives featured through
              our videos
            </h6>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={10} md={5} className="m-0 pb-3">
            <Card className="text-white transparent-bg">
              <Card.Img src={mediaThumb} alt="Card image" />
              <Card.ImgOverlay className="d-flex justify-content-center align-items-end">
                <Card.Title>Documentary</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col xs={10} sm={10} md={5} className="m-0 pb-3">
            <Card className="text-white transparent-bg">
              <Card.Img src={mediaThumb1} alt="Card image" />
              <Card.ImgOverlay className="d-flex justify-content-center align-items-end">
                <Card.Title>Explainers</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col xs={10} sm={10} md={5} className="m-0 pb-3 ">
            <Card className="text-white transparent-bg">
              <Card.Img src={mediaThumb2} alt="Card image" />
              <Card.ImgOverlay className="d-flex justify-content-center align-items-end">
                <Card.Title>Talks</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col xs={10} sm={10} md={5} className="m-0 pb-3">
            <Card className="text-white transparent-bg">
              <Card.Img src={mediaThumb3} alt="Card image" />
              <Card.ImgOverlay className="d-flex justify-content-center align-items-end">
                <Card.Title>Special Courses</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ChannelSection;
